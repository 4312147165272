import { useTranslation } from 'react-i18next';

import { Checkbox, Field, Form, Link, TextArea, Typography } from '@/components';
import { Route as RoutePath } from '@/constants';
import { nullableStringSetter } from '@/helpers';
import { DealDetails } from '@/features/deals/types';
import { useCallback, useState } from 'react';
import { DocumentUpload, FileSchema } from '@/features/files';
import { useUploadAttachment } from '@/features/offers/hooks';
import { useRemoveAttachment } from '@/features/projects';
import { AdvisoryServiceForm, AdvisoryServiceFormFields } from '@/features/services';
import { UseFormRegister } from 'react-hook-form';

export type SendInterestFormFields = {
  express_interest: string;
  company_information: string;
  additional_information: string;
  attachments: FileSchema[];
  organization_approval: boolean;
  terms_and_conditions_accepted: boolean;
  advisory_service_information: {
    legal_advisors: string;
    financial_advisors: string;
    technical_advisors: string;
    other: string;
  };
};

export type SendInterestFormProps = {
  readonly id: string;
  readonly deal: DealDetails;
  readonly onSubmit: (data: SendInterestFormFields) => void;
};

export function SendInterestForm({ id, deal, onSubmit }: SendInterestFormProps) {
  const { t } = useTranslation(['offers']);

  const textareaMaxLength = 4000;

  const options = {
    defaultValues: {
      express_interest: '',
      company_information: '',
      additional_information: '',
      attachments: [],
      advisory_service_information: {},
      organization_approval: false,
      terms_and_conditions_accepted: false,
    },
  };
  const [files, setFiles] = useState<FileSchema[]>([]);
  const [deletedFiles, setDeletedFiles] = useState<string[]>([]);
  const uploadAttachment = useUploadAttachment();
  const { deleteAllAttachments } = useRemoveAttachment();

  const onSubmitForm = useCallback(
    (data: SendInterestFormFields) => {
      data.attachments = files.map((file) => {
        directus_file_id: file.id;
      });
      deleteAllAttachments(deletedFiles);
      onSubmit(data);
    },
    [deletedFiles, files, onSubmit]
  );

  return (
    <Form<SendInterestFormFields> id={id} onSubmit={onSubmitForm} options={options}>
      {({ formState, register, setValue }) => (
        <div className="space-y-6">
          <Field
            id="express_interest"
            label={t('Express your interest')}
            error={formState.errors['express_interest']}
            delay={0}
            classes={{ field: 'space-y-1' }}
          >
            <TextArea
              id="express_interest"
              color={formState.errors['express_interest'] ? 'error' : 'primary'}
              registration={register('express_interest', {
                required: t('This field is required'),
                setValueAs: nullableStringSetter,
                maxLength: textareaMaxLength,
              })}
              maxLength={textareaMaxLength}
            />
          </Field>

          <Field
            id="company_information"
            label={t('Company information')}
            error={formState.errors['company_information']}
            delay={0}
            classes={{ field: 'space-y-1' }}
          >
            <TextArea
              id="company_information"
              color={formState.errors['company_information'] ? 'error' : 'primary'}
              registration={register('company_information', {
                required: t('This field is required'),
                setValueAs: nullableStringSetter,
                maxLength: textareaMaxLength,
              })}
              maxLength={textareaMaxLength}
            />
          </Field>

          <Field
            id="additional_information"
            label={t('Additional information')}
            error={formState.errors['additional_information']}
            delay={0}
            classes={{ field: 'space-y-1' }}
          >
            <TextArea
              id="additional_information"
              color={formState.errors['additional_information'] ? 'error' : 'primary'}
              registration={register('additional_information', {
                setValueAs: nullableStringSetter,
                maxLength: textareaMaxLength,
              })}
              maxLength={textareaMaxLength}
            />
          </Field>

          <Field id="attachments" label={t('Supporting documents')} delay={0.1} classes={{ field: 'space-y-1' }}>
            <DocumentUpload
              multiple={true}
              files={files}
              onSelectFiles={(newFiles) => {
                for (const file of newFiles) {
                  uploadAttachment(file, (data) => {
                    setFiles((files) => [...files, data]);
                  });
                }
              }}
              onUnSelectFiles={(oldFile) => {
                setDeletedFiles((files) => [...files, oldFile.id]);
                setFiles((files) => files.filter(({ id }) => id !== oldFile.id));
              }}
            />
          </Field>

          <div>
            <Typography as="h5" color="primary" size="title" weight="normal">
              {t('Advisory Services')}
            </Typography>
            <Typography as="p" size="body-3" color="tertiary" weight="normal" className="mt-1">
              {t(
                'Do you need professional advice for your project? At nTeaser, we have selected the best advisors to guide you. Selecting "Yes" is non-binding; we will only conect you with the right expert! Want to know more? Visit our'
              )}{' '}
              <Link to={RoutePath.Services} target="_blank">
                {t('Advisors section')}
              </Link>{' '}
              {t('and discover them all!.')}
            </Typography>
          </div>

          <AdvisoryServiceForm
            register={register as UseFormRegister<AdvisoryServiceFormFields>}
            formState={formState}
          />

          <div className="rounded-md bg-gray-100 p-4">
            <Field
              id="organization_approval"
              error={formState.errors['organization_approval']}
              delay={0.9}
              classes={{ field: 'space-y-1' }}
            >
              <Checkbox
                id="organization_approval"
                color="primary"
                registration={register('organization_approval', {
                  required: t('This consent is required'),
                })}
              >
                <Typography as="p" color="secondary" size="body-3" weight="normal">
                  {t(
                    'I confirm I have received the neccessary approvals within my organisation to submit this expression of interest.'
                  )}
                </Typography>
              </Checkbox>
            </Field>
            <Field
              id="terms_and_conditions_accepted"
              error={formState.errors['terms_and_conditions_accepted']}
              delay={1.05}
              classes={{ field: 'space-y-1' }}
            >
              <Checkbox
                id="terms_and_conditions_accepted"
                color="primary"
                registration={register('terms_and_conditions_accepted', {
                  required: t('You must accept the terms and conditions to submit this form.'),
                })}
              >
                <Typography as="p" color="secondary" size="body-3" weight="normal">
                  {t('I accept nTeaser’s ')}
                  <Link to={RoutePath.TermsAndConditions} target="_blank">
                    {t('Terms and Conditions')}
                  </Link>
                  {t(' and accept to pay a success fee of 1% of this NBO at SPA signing.')}
                </Typography>
              </Checkbox>
            </Field>
          </div>
        </div>
      )}
    </Form>
  );
}
