import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import { AnimationShell, Chip, Icons, Link, Typography } from '@/components';
import { ChipColor } from '@/components/chips/types';
import { Route } from '@/constants';
import { OpportunityItemList } from '@/features/opportunities/types';
import { displayDate } from '@/helpers';
import { getChipFromOpportunity } from '@/features/opportunities/helpers';

export type MyOpportunitiesItemProps = {
  readonly opportunity: OpportunityItemList;
  readonly index?: number;
};

export function MyOpportunitiesItem({ opportunity, index = 0 }: Readonly<MyOpportunitiesItemProps>) {
  const { t } = useTranslation(['opportunities']);
  const statusChip = getChipFromOpportunity(opportunity);

  return (
    <motion.div
      initial={{ y: 0 }}
      whileHover={{ y: -6 }}
      transition={{ type: 'spring', stiffness: 100 }}
      className="w-full"
    >
      <Link to={Route.Opportunity.replace(':opportunityId', opportunity.id)} className="hover:no-underline">
        <AnimationShell
          type="scale"
          delay={0.1 * index}
          className="h-full w-full cursor-pointer rounded-md border border-neutral-50 bg-base-white p-4 shadow-sm transition hover:shadow-xl"
        >
          <div className="inline-flex w-full items-center justify-between gap-4">
            <Icons icons={opportunity.technologies.icons.states} />

            <div className="inline-flex items-center justify-start gap-2">
              <Chip color={statusChip.color}>{statusChip.name}</Chip>
            </div>
          </div>

          <div className="space-y-0.5">
            <div className="inline-flex items-center justify-start gap-2">
              <Typography as="span" size="body-2" color="primary" weight="semibold">
                {opportunity.name}
              </Typography>
            </div>

            <div className="inline-flex w-full items-center justify-between gap-2">
              <div>
                {opportunity.projects.map((project, index) => (
                  <Chip key={`project-${index}`} color="display" index={index}>
                    {project.name}
                  </Chip>
                ))}
              </div>

              <Typography as="p" size="body-3" color="tertiary" weight="normal">
                {displayDate(opportunity.deadline.date)}
              </Typography>
            </div>
          </div>
        </AnimationShell>
      </Link>
    </motion.div>
  );
}
